import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Input,
	Label,
	Form,
	FormGroup,
	FormFeedback,
} from "reactstrap";
import Web3 from 'web3';
import hasPermission from "../../common/HasPermission";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../config";
import Erc20ABI from "../../ABI/Erc20.json"
import PublicSaleABI from "../../ABI/publicsaleFactory.json"
import {
	createAirdrop,
	clearResponse,
	updateProject,
} from "../../store/admin/actions";

const ContractInteraction = ({
	initializeWeb3,
	isAdditionalSetting,
	projectSetting,
	couldHaveAddUpdatePermission,
	settingData,
	formik,
	formikReclaim,
	switchOrAddChain,
}) => {

	const dispatch = useDispatch();
	const [airdropAddress, setAirdropAddress] = useState([]);
	const [airdropRoot, setAirdropProofRoot] = useState([]);
	const [airdropwalletscount, setAirdropwallets] = useState([]);
	const [inputcount, setInputs] = useState([]);
	const [isDeployer, setIsDeployer] = useState(false)
	const { response, auth } = useSelector((state) => ({
		response: state.admin.response,
		auth: state.auth,
	}));

	useEffect(() => {
		if (settingData.id !== undefined) {
			const fetchAirdropaddress = async () => {
				if(settingData.id !== undefined){
					try {
						const response = await axios.get(`${apiUrl}/proofs/airdrop/${settingData.id}`);
						const newValue = response.data.walletAddress?.replaceAll(",", "\n")?.replaceAll("~", ":") //.replaceAll(" ","");
						setAirdropAddress(newValue);
						setAirdropProofRoot(response.data.proof);
						const lineCount = (newValue.match(/\n/g) || []).length + 1;
						setInputs(lineCount)
						setAirdropwallets(lineCount)
					} catch (error) {}
				}
			};
			fetchAirdropaddress();
			setAirdropAddress("")
			setAirdropProofRoot("")
			setInputs(0)
			setAirdropwallets(0)
		}
	}, [settingData]);

	useEffect(() => {
		const fetchDeployer = async () => {
			const web3 = initializeWeb3();
			if (!web3) {
				Swal.fire('Error', 'Web3 initialization failed.', 'error');
				return;
			}
			const accounts =  window.ethereum.request({ method: 'eth_requestAccounts' });
			const deployer = await sessionStorage.getItem('metamaskAddress', accounts[0]); 
			if(deployer !== null){
			try{
				const publicSaleABI = PublicSaleABI;
				let contractAddress = await settingData.network.factoryContractAddress
				let contract = new web3.eth.Contract(publicSaleABI, contractAddress);
				const isAdmin = await contract.methods.adminList(deployer).call();
				if (deployer.toString().toLowerCase() === settingData?.deployer.toString().toLowerCase() || isAdmin) {
					setIsDeployer(true)
					return
				}
					setIsDeployer(false)
					Swal.fire({
					title: "Error!",
					text: "Wallet is unauthorized",
					icon: "error",
				})
			}catch(e){
				console.log(e)
			}
			}	
		}
		fetchDeployer()
	}, [settingData])

	useEffect(() => {
		const fetchResponse = async() => {
		if (response) {
			if (response.message === "Project updated successfully" ) {
				Swal.fire({
					title: "Success!",
					text: response.message,
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			} else if (response.code === "201" || response.proof) {
				// const web3 = initializeWeb3();
				// if (!web3) {
				// 	Swal.fire('Error', 'Web3 initialization failed.', 'error');
				// 	return;
				// }
				// if(settingData?.tokenAddress){
				// 	const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				// 	const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
				// 	if (!fromAddress) {
				// 		Swal.fire('Error', 'Wallet not connected', 'error');
				// 		return;
				// 	}
				// 	try {
				// 		const publicSaleABI = PublicSaleABI;
				// 		const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
				// 		const uploadAirdrop = await contractPublicSale.methods
				// 			.addAllocationAndAirdropHash(response.proof, 1, 0)
				// 			.send({ from: fromAddress });
				// 		Swal.fire('Success', 'Airdrop hash added successfully!', 'success');
				// 	} catch (error) {
				// 		console.error('Error setting airdrop hash:', error);
				// 		Swal.fire('Transaction Error', 'Error during airdrop hash setting: ' + error.message, 'error');
				// 	}
				// }
			}
			else if (response.message) {
				Swal.fire({
					title: "Error!",
					text: response.message,
					icon: "error",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
	}
	fetchResponse()
	}, [dispatch, response]);

	const refundOption = async (e) => {
		e.preventDefault()

		const web3 = initializeWeb3();
		if (!web3) return console.error('Web3 initialization failed.');

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			return console.error('Network switch/addition failed:', error);
		}

		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) return  Swal.fire({
			icon: 'error',
			title: 'Wallet not connected',
			text: 'Please connect your wallet',
		});

		const publicSaleABI = PublicSaleABI;
		const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
		if (formik.values.refundType == "Reclaim Address") {
			try {
				const addLiquidityReceipt = await contractPublicSale.methods
					.addbuyBackOrReclaimAddress(formik.values.refundAddress, 1)
					.send({ from: fromAddress });
					var formData = new FormData();
					formData.append("refundType", "Refund");
					await dispatch(updateProject({ id: settingData.id, data: formData }));
				
			} catch (error) {
				Swal.fire({
					title: "Error!",
					text: `Error during Reclaim address transaction: ${error}`,
					icon: "error",
				})
			}
		} else if (formik.values.refundType === "Buyback Address") {
			try {
				const addLiquidityReceipt = await contractPublicSale.methods
					.addbuyBackOrReclaimAddress(formik.values.refundAddress, 0)
					.send({ from: fromAddress });
					var formData = new FormData();
					formData.append("refundType", "Buyback");
					await dispatch(updateProject({ id: settingData.id, data: formData }));
			} catch (error) {
				Swal.fire({
					title: "Error!",
					text: `Error during Buyback address transaction: ${error}`,
					icon: "error",
				})
			}
		}
	}

	const reclaimOption = async () => {
		const web3 = initializeWeb3();
		if (!web3) return console.error('Web3 initialization failed.');

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			return console.error('Network switch/addition failed:', error);
		}


				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
				if (!fromAddress) return Swal.fire({
					icon: 'error',
					title: 'Wallet not connected',
					text: 'Please connect your wallet',
				});


		const publicSaleABI = PublicSaleABI;
		const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
		try {
			const projectOwner = await contractPublicSale.methods.project().call();
			if (fromAddress.toString().toLowerCase() !== projectOwner[10].toString().toLowerCase()) {
				Swal.fire({
					icon: 'error',
					title: 'Permission Denied',
					text: 'You do not have permission to perform this action.',
				});
				return;
			}
			let depositFee = formikReclaim.values.depositFee * 10
			let withdrawFee = formikReclaim.values.withdrawFee * 10
			let tokenClaimFee = formikReclaim.values.tokenClaimFee * 10

			if (depositFee >= 0.1 * 10 && withdrawFee >= 0.1 * 10 && tokenClaimFee >= 0.1 * 10) {
				const setFeeReceipt = await contractPublicSale.methods
					.setFees(depositFee, withdrawFee, tokenClaimFee)
					.send({ from: fromAddress });

				Swal.fire({
					icon: 'success',
					title: 'Transaction Successful',
					text: 'The fees have been set successfully.',
				});

				try {
					var formData = new FormData();
					formData.append("depositFee", depositFee);
					formData.append("withdrawFee", withdrawFee);
					formData.append("tokenClaimFee", tokenClaimFee);
					const response = await axios.patch(`${apiUrl}/projects/${settingData.id}`, formData);
					// console.log('Project updated successfully:', response.data);
					return response.data;
				} catch (error) {
					Swal.fire({
						title: 'Error!',
						text: `Failed to update the project: ${depositFee} ${withdrawFee} ${tokenClaimFee} ${error.response ? error.response.data.message : error.message}`,
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					throw error;
				}


			} else {
				Swal.fire({
					icon: 'error',
					title: 'Invalid Input',
					text: 'All fee values must be greater than or equal to 0.1.',
				});
			}
		} catch (error) {
			console.error('Error during transaction:', error);
			Swal.fire({
				icon: 'error',
				title: 'Transaction Failed',
				text: error.message,
			});
		}
		formik.resetForm();
	}

	const addLiquidityService = async () => {
		const web3 = initializeWeb3();
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			Swal.fire('Error', 'Network switch/addition failed: ' + error.message, 'error');
			return;
		}

		const erc20ABI = Erc20ABI;
		if (!erc20ABI) {
			Swal.fire('Error', 'ERC20 ABI not found.', 'error');
			return;
		}

		const erc20Contract = new web3.eth.Contract(erc20ABI, settingData.tokenAddress);
		if (!erc20Contract) {
			Swal.fire('Error', 'ERC20 contract initialization failed.', 'error');
			return;
		}

		// const fromAddress = await getPrimaryAccount(web3);
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				const fromAddress = await sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}
		try {
			const publicSaleABI = PublicSaleABI;
			const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);

			const tokenRemaining = await contractPublicSale.methods.tokenRemainingLiquidity().call();
			let number = BigInt(tokenRemaining);

			if (number > 0) {
				Swal.fire('Error', 'Token liquidity is already present and must be zero before adding new liquidity.', 'error');
				return;
			}

			const decimals = await erc20Contract.methods.decimals().call();
			const decimalLiquidity = BigInt(settingData.liquidity) * 10n ** BigInt(decimals);

			const approvalReceipt = await erc20Contract.methods
				.approve(settingData.launchpadContractAddress, decimalLiquidity.toString())
				.send({ from: fromAddress });

			const addLiquidityReceipt = await contractPublicSale.methods
				.addLiquidity(decimalLiquidity.toString())
				.send({ from: fromAddress });

			Swal.fire('Success', 'Liquidity added successfully!', 'success');
		} catch (error) {
			console.error('Error during liquidity transaction:', error);
			Swal.fire('Transaction Error', 'Error during liquidity transaction: ' + error.message, 'error');
		}
	};

	const uploadAllocationHash = async () => {

		const web3 = initializeWeb3();
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			Swal.fire('Error', 'Network switch/addition failed: ' + error.message, 'error');
			return;
		}
		// const fromAddress = await getPrimaryAccount(web3);
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}

		try {
			const hash = await axios.get(`${apiUrl}/proofs/project/${settingData.id}`)
			if (hash.data.message){
				Swal.fire({
					title: "Error!",
					text: hash.data.message,
					icon: "error",
				})
				return
			}
				const publicSaleABI = PublicSaleABI;
				const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
			
				console.log("hash",settingData.launchpadContractAddress)
				const uploadAllocation = await contractPublicSale.methods
					.addAllocationAndAirdropHash(hash.data.root, 0, 0)
					.send({ from: fromAddress });
				Swal.fire('Success', 'Allocation hash added successfully!', 'success');
		} catch (error) {
			console.error('Error setting allocation hash:', error);
			Swal.fire('Transaction Error', 'Error during allocation hash setting: ' + error.message, 'error');
		}
	};

	const handleTextareaChange = (e) => {
		setAirdropAddress(e.target.value)
		if (airdropAddress) {
			const lineCount = (airdropAddress?.match(/\n/g) || []).length + 1; // Count the number of lines
			setInputs(lineCount)
		}
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			walletAddress: airdropAddress && airdropAddress.walletAddress ? airdropAddress.walletAddress : "",
		},
		onSubmit: async () => {
			let newValue = airdropAddress?.replaceAll("\n", ",")?.replaceAll(":", "~");
			if (newValue.endsWith(",")) {
				newValue = newValue.slice(0, -1);
			}
			let resp = await axios.post(`${apiUrl}/proofs/airdrop`, {projectId: settingData.id, walletsData: newValue})
			if(resp.data){
				const web3 = initializeWeb3();
				if (!web3) {
					Swal.fire('Error', 'Web3 initialization failed.', 'error');
					return;
				}
				if(settingData?.tokenAddress){
					const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
					const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
					if (!fromAddress) {
						Swal.fire('Error', 'Wallet not connected', 'error');
						return;
					}
					try {
						const publicSaleABI = PublicSaleABI;
						const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
						const uploadAirdrop = await contractPublicSale.methods
							.addAllocationAndAirdropHash(resp.data.proof, 1, 0)
							.send({ from: fromAddress });
						Swal.fire('Success', 'Airdrop hash added successfully!', 'success');
					} catch (error) {
						console.error('Error setting airdrop hash:', error);
						Swal.fire('Transaction Error', 'Error during airdrop hash setting: ' + error.message, 'error');
					}
				}
			}
		}
	};

	const validation = useFormik(useFormikOptions);

	const couldHaveProofPermission = () => {
		if (auth.user.isSuperAdmin) return true
		const isUpdatePermission = hasPermission(
			["update:Proofs"],
			auth.user.allowedRoles
		);
		const isAddPermission = hasPermission(
			["create:Proofs"],
			auth.user.allowedRoles
		);
		const isViewPermission = hasPermission(
			["read:Proofs"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission && isViewPermission) return true;
		else if (isUpdatePermission && !isEmpty(details.id)) return true;
		else if (isAddPermission && isEmpty(details.id)) return true;
		else return false;
	};

	return (
		<div className="page-content">
			{!isDeployer ? "" :
				<Modal
					isOpen={
						isAdditionalSetting
					}
					toggle={
						projectSetting
					}
					size="xl"
					centered={true}
				>
					{/* <h1>Add Addtional Setting</h1> */}
					<ModalHeader
						toggle={
							projectSetting
						}
						tag="h4"
					>
					</ModalHeader>
					<ModalBody>
						<fieldset
							disabled={
								!couldHaveAddUpdatePermission()
							}
						>
							<Form
								onSubmit={(
									e
								) => {
									e.preventDefault();
									handleSubmitNetwork();
									return false;
								}}
							>
								<Row>
									<Col
										xs={
											12
										}
									>
										{/* Refund Type */}

										<div className="row align-items-center mb-3 mt-3">
											<div className="col-lg-6 mb-3">
												<button
													type="button"
													onClick={addLiquidityService}
													className="btn btn-sm btn-primary waves-effect waves-light w-50"
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Add Liquidity {settingData.liquidity} {settingData.tokenSymbol}
												</button>
											</div>

											<div className="col-lg-6 mb-3">
												<button
													type="button"
													onClick={uploadAllocationHash}
													className="btn btn-sm btn-primary waves-effect waves-light w-50"
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Upload Allocation Hash
												</button>
											</div>


											<div className="col-lg-4 mb-3">
												<Label htmlFor="refund-type" className="form-label">
													Redistribution Of Remaining Token
												</Label>
												<Input
													id="refund-type"
													name="refundType"
													type="select"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.refundType || ""}
													invalid={
														formik.touched.refundType && Boolean(formik.errors.refundType)
													}
												>
													<option value="" disabled>
														Select option
													</option>
													<option value={"Buyback Address"}>
														Buyback Address
													</option>
													<option value={"Reclaim Address"}>
														Reclaim Address
													</option>
												</Input>
												{formik.touched.refundType && formik.errors.refundType ? (
													<FormFeedback>{formik.errors.refundType}</FormFeedback>
												) : null}
											</div>

											{/* Refund Address Field */}
											<div className="col-lg-4 mt-3">
												<Input
													name="refundAddress"
													type="text"
													placeholder="Address"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.refundAddress || ""}
													invalid={
														formik.touched.refundAddress && Boolean(formik.errors.refundAddress)
													}
													style={{ height: "38px" }} // Adjust the height to match the button
												/>
												{formik.touched.refundAddress && formik.errors.refundAddress ? (
													<FormFeedback>{formik.errors.refundAddress}</FormFeedback>
												) : null}
											</div>


											<div className="col-lg-4 mt-3">
												<button
													type="button"
													onClick={(e) => refundOption(e)}
													className="btn btn-sm btn-primary waves-effect waves-light w-20"
													style={{ height: "38px" }}
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Submit
												</button>
											</div>
										</div>

										{/* Fee Option (%) */}
										<Label htmlFor="refund-type" className="form-label">
											Fee Option (%)
										</Label>
										<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
													{/* USDT Deposit Fee */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="depositFee">USDT Deposit Fee</Label>
														<Input
															id="depositFee"
															name="depositFee"
															type="text"
															placeholder="Enter USDT Deposit Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.depositFee || ""}
															invalid={formikReclaim.touched.depositFee && Boolean(formikReclaim.errors.depositFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.depositFee && formikReclaim.errors.depositFee && (
															<FormFeedback>{formikReclaim.errors.depositFee}</FormFeedback>
														)}
													</div>
													{/* USDT Withdrawal Fee */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="withdrawFee">USDT Withdrawal Fee</Label>
														<Input
															id="withdrawFee"
															name="withdrawFee"
															type="text"
															placeholder="Enter USDT Withdrawal Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.withdrawFee || ""}
															invalid={formikReclaim.touched.withdrawFee && Boolean(formikReclaim.errors.withdrawFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.withdrawFee && formikReclaim.errors.withdrawFee && (
															<FormFeedback>{formikReclaim.errors.withdrawFee}</FormFeedback>
														)}
													</div>

													{/* Token Claim Fee Field */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="tokenClaimFee">Token Claim Fee</Label>
														<Input
															id="tokenClaimFee"
															name="tokenClaimFee"
															type="text"
															placeholder="Enter Token Claim Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.tokenClaimFee || ""}
															invalid={formikReclaim.touched.tokenClaimFee && Boolean(formikReclaim.errors.tokenClaimFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.tokenClaimFee && formikReclaim.errors.tokenClaimFee && (
															<FormFeedback>{formikReclaim.errors.tokenClaimFee}</FormFeedback>
														)}
													</div>

													{/* Submit Button */}

												</div>
												<div className="col-lg-4 mt-3">
													<button
														type="button"
														onClick={reclaimOption}
														className="btn btn-sm btn-primary waves-effect waves-light w-100"
														style={{ height: "38px" }}
													>
														<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i> Submit
													</button>
												</div>
											</div>
										</div>



										{/* Add Reclaim Address */}


									</Col>
								</Row>


								{/* <Row>
									<Col>
										<div className="text-end mt-3">
											<button
												type="submit"
												className="btn btn-success save-user"
											>
												Save
											</button>
										</div>
									</Col>
								</Row> */}
							</Form>
						</fieldset>
						<Form
							disabled
							onSubmit={(e) => {
								e.preventDefault();
								validation.handleSubmit();
								return false;
							}}
						>
							<FormGroup>
								<fieldset
									disabled={
										!couldHaveProofPermission()
									}
								>
									<Label for="airdropAddress">Airdrop Details</Label>
									<div className="col-lg-12 mb-3">
										<span>
											Root : {airdropRoot}
										</span><br></br>
										<span>
											Total whitelisted wallets : {airdropwalletscount}
										</span><br></br>
										<span>
											Total Inputs count : {inputcount}
										</span><br></br>
										{formik.touched.refundType && formik.errors.refundType ? (
											<FormFeedback>{formik.errors.refundType}</FormFeedback>
										) : null}
										<br></br>
										<Input
											type="textarea"
											name="airdropAddress"
											id="airdropAddress"
											value={airdropAddress}
											onChange={handleTextareaChange}
											style={{ minHeight: "38px", maxHeight: "300px", height: inputcount * 24 }}
											onBlur={formikReclaim.handleBlur}
										/>
										<div className="text-end mt-3">
											<button type="submit" className="btn btn-success save-user">
												Submit
											</button>
										</div>
									</div>
								</fieldset>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
			}
		</div >
	)
}



export default ContractInteraction