import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import HasAnyPermission from "../../common/Permission";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import "../../assets/scss/launchpad.scss";
import Erc20ABI from "../../ABI/Erc20.json"
import PublicSaleABI from "../../ABI/publicsaleFactory.json"

const Transaction = (props) => {

		const [contract1Address, setContract1Address] = useState('');
		const [contract2Address, setContract2Address] = useState('');
		const [error, setError] = useState('');
		const [loading, setLoading] = useState(false);
	  
		const deployContract = async (abi, bytecode) => {
		  try {
			setLoading(true);
			setError('');
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const signer = provider.getSigner();
			const factory = new ethers.ContractFactory(abi, bytecode, signer);
			const contract = await factory.deploy();
			await contract.deployed();
			setLoading(false);
			return contract.address;
		  } catch (err) {
			setError(err.message);
			setLoading(false);
			return null;
		  }
		};
	  
		const handleDeployContract1 = async () => {
		  const address = await deployContract(contract1ABI, contract1Bytecode);
		  if (address) setContract1Address(address);
		};
	  
		const handleDeployContract2 = async () => {
		  const address = await deployContract(contract2ABI, contract2Bytecode);
		  if (address) setContract2Address(address);
		};

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				active: item,
				action: item,
			};
		});
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Deploy Smart Contract | Rampstarter</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Transaction"
						breadcrumbItem="Transaction"
					/>
					<Card>
						<CardBody>
							<Row>
								<Col xl="12">
									<div className="table-rep-plugin">
										<div className="table-responsive">
												
										</div>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Transaction;
