import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin";
import Role from "../pages/Admin/Role";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import LaunchpadOption from "../pages/LaunchpadOption";
import Launchpad from "../pages/Launchpad";
import NetworkInfo from "../pages/NetworkInfo";
import Transaction from "../pages/Transaction";

const userRoutes = [
	{ path: "/dashboard", component: Dashboard},
	{
		path: "/admins", 
		exact: true,
		component: Admin,
		permission: ["create:Users", "read:Users","update:Users", "delete:Users"],
	},
	{
		path: "/roles",
		exact: true,
		component: Role,
		permission: ["create:Roles", "read:Roles","update:Roles","delete:Roles"],
	},
	{
		path: "/launchpad-option",
		exact: true,
		component: LaunchpadOption,
		permission: ["create:Proofs","read:Proofs"],
	},
	{
		path: "/network-info",
		exact: true,
		component: NetworkInfo,
		permission: ["create:Networks","read:Networks","update:Networks","delete:Networks"],
	},
	{
		path: "/launchpad",
		exact: true,
		component: Launchpad,
		permission: ["create:Projects","read:Projects","update:Projects","delete:Projects"],
	},
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
	{ path: "/logout", exact: true, component: Logout },
	{ path: "/login", exact: true, component: Login },
];

const superAdminRoutes = [
	{ path: "/transaction", exact: true, component: Transaction ,permission: [], isSuperAdmin: true},
];

export { userRoutes, authRoutes ,superAdminRoutes};
