import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from 'styled-components';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	FormGroup,
	Label,
	Input,
	CardTitle,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik, Formik, Field, ErrorMessage } from "formik";
import Dropzone from "react-dropzone";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import axios from "axios";
import {
	create,
	update,
	remove,
	toggle,
	clearResponse,
} from "../../store/networkInfo/actions";
import Swal from "sweetalert2";
import Web3 from "web3";
import Erc20 from "../../ABI/Erc20.json"




const LaunchpadOption = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.networkInfo.response,
		auth: state.auth,
	}));
	const [addressValidated, setAddressValidated] = useState(false);
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isOpenAddEditNetwork, toggleAddEditNetwork] = useState(false)
	const [currencies, setCurrencies] = useState([]);
	const [getNetwork, setNetwork] = useState([])
	const [details, handleformData] = useState({
		name: "",
		chainId: "",
		contractAddress: "",
		isActive: false,
		currencies: [],
	});

	const [createNetwork, handleformNetwork] = useState({
		name: "",
		isActive: false,
		factoryContractAddress: "",
		rpc: "",
		chainId: ""

	})


	// const [contractAddress, setContractAddress] = useState("")
	const [contractDetails, setContractDetails] = useState({
		contractAddress: "",
		currencyName: "",
		symbol: "",
		currencyDecimal: 0,
	})




	useEffect(() => {
		// Asynchronous function to fetch contract details
		//  0xcc1b99dDAc1a33c201a742A1851662E87BC7f22C
		const fetchContractDetails = async () => {
			try {
				const web3 = new Web3(window.ethereum);
				const contract = new web3.eth.Contract(Erc20, contractDetails.contractAddress);
				let name = await contract.methods.name().call();
				let symbol = await contract.methods.symbol().call();
				let decimal = await contract.methods.decimals().call();
				setContractDetails(prevDetails => ({
					...prevDetails,
					name,
					symbol,
					decimal: Number(decimal),
				}));

				// console.log("name ", name, symbol, decimal);
			} catch (error) {
				// console.error("Error fetching contract details:", error);
			}

		};

		fetchContractDetails();

	}, [contractDetails.contractAddress])

	useEffect(() => {
		if (response && response.status === 200) {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.message, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
			Swal?.close();
		} else if (response && response?.message) {
			refreshTableData.current();
			toast.error(response.message, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
			Swal?.close();
		}
		if (response.affected === 1) {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			if (response.generatedMaps) {
				Swal.fire({
					title: "Success!",
					text: "User Updated",
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			} else {
				Swal.fire({
					title: "Success!",
					text: "Network has been deleted",
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
	}, [dispatch, response]);

	useEffect(() => {
		useFormikForAddCurrency.setValues({
			contractAddress: contractDetails.contractAddress,
			name: contractDetails.name,
			symbol: contractDetails.symbol,
			currencyDecimal: contractDetails.currencyDecimal,
		});
	}, [contractDetails]);


	let validationSchema = {
		name: Yup.string().required("Please enter name."),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			name: details && details.name ? details.name : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			if (details.id) {
				dispatch(update(details));
			} else {
				dispatch(create(values));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const initializeWeb3 = () => {
		if (window.ethereum) {
			return new Web3(window.ethereum);
		} else {
			console.error('MetaMask is not installed');
			return null;
		}
	};



	const switchOrAddChain = async (data) => {
		const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
		if (currentChainId !== data.chainId) {
			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: data.chainId }],
				});
			} catch (error) {
				if (error.code === 4902) {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [{
							chainId: data.chainId,
							chainName: data.name,
							rpcUrls: [data.rpc],
							blockExplorerUrls: [data.blockExplorerUrl],
						}],
					});
				} else {
					throw new Error(`Failed to switch to ${data.name}`);
				}
			}
		}
	};

	const handleAddEditModal = async (data = null) => {
		setContractDetails({})

		if (!isEmpty(data) && data?.id) {

			const web3 = initializeWeb3();
			if (!web3) return;
			try {
				await switchOrAddChain(data);
			} catch (error) {
				console.error(error);
				return;
			}
			handleformData(data);
		} else {
			handleformData({
				name: "",
				isActive: true,
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};


	const handleChangeData = (name, index) => (e) => {
		let detailData = [...details.currencies];
		detailData[index][name] = e.target.value;
		handleformData({
			...details,
			currencies: detailData,
		});
	};

	const getContract = (event) => {
		setContractDetails({ contractAddress: event.target.value });

	}

	const addCurrency = (e) => {
		e.preventDefault();
		handleformData({
			...details,
			currencies: [
				...details.currencies,
				{
					name: "",
					address: "",
					decimal: "",
					isNative: false,
					feeOption: [{ fee: "", description: "" }],
					isActive: true,
				},
			],
		});
	};



	const addFeeDescription = (e, dataIndex) => {
		e.preventDefault();
		if (details.currencies?.[dataIndex]) {
			details.currencies[dataIndex] = {
				...details.currencies[dataIndex],
				feeOption: [
					...details.currencies[dataIndex].feeOption,
					{ fee: "", description: "" },
				],
			};
			handleformData({
				...details,
			});
		}
	};

	const removeFeeDescription = (e, dataIndex, index) => {
		e.preventDefault();
		let currencyDetails = { ...details };
		if (currencyDetails.currencies[dataIndex].feeOption) {
			currencyDetails.currencies[dataIndex].feeOption.splice(index, 1);
			handleformData({
				...currencyDetails,
			});
		}
	};

	const removeCurrency = (e, index) => {
		// console.log("eee ",e,"index ",index,"d...",details.currencies[index].id)
		let id = details.currencies[index].id
		e.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`${apiUrl}/currencies/${id}`)
					.then(response => {
						Swal.fire(
							'Deleted!',
							'Your item has been deleted.',
							'success'
						);
					})
					.catch(error => {
						// Handle any errors
						Swal.fire(
							'Failed!',
							'There was an error deleting the item: ' + error.message,
							'error'
						);
					});
			}
		});

		details.currencies.splice(index, 1);
		handleformData({
			...details,
			currencies: [...details.currencies],
		});
	};

	const currencyToggleItem = (e, index) => {
		e.preventDefault();
		details.currencies[index].isActive = !details.currencies[index].isActive;
		handleformData({
			...details,
			currencies: [...details.currencies],
		});
	};

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(id));
				}
			});
		}
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			label: "ChainId",
			name: "chainId",
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			name: "active",
			label: "Active",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (rowData) => (
					<div className="square-switch">
						<Input
							type="checkbox"
							id={`square-switch-${rowData.id}-banner`}
							switch="none"
							checked={rowData.isActive}
							onClick={() => {
								toggleItem(rowData);
							}}
							onChange={() => {
								toggleItem(e, index);
							}}
							disabled={!(auth.user.isSuperAdmin || hasPermission(["update:Networks"], auth.user.allowedRoles))}
						/>
						<Label
							htmlFor={`square-switch-${rowData.id}-banner`}
							data-on-label="Yes"
							data-off-label="No"
						></Label>
					</div>
				),
			},
		},
		{
			label: "Currencies",
			name: "currencies",
			options: {
				filter: true,
				sort: false,
				empty: true,
				download: false,
				display: (hasPermission(
					[
						"update:Currencies",
						"delete:Currencies",
						"read:Currencies",
					],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
				viewColumns: (hasPermission(
					[
						"update:Currencies",
						"delete:Currencies",
						"read:Currencies",
					],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
				customBodyRender: (item) => {
					return (
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							{item.currencies.map((currency, index) => (
								<div key={index} style={{
									margin: '0 10px 10px 0',
									padding: '5px 10px',
									backgroundColor: currency.isActive ? '#28a745' : '#dc3545', // Green when active, red when not
									borderRadius: '4px',
									color: 'white',
									fontWeight: 'bold',
									display: 'inline-block'
								}}>
									<button onClick={() => toggleCurrencyStatus(currency)}
										disabled={!(auth.user.isSuperAdmin || hasPermission(["update:Currencies"], auth.user.allowedRoles))}
										style={{
											backgroundColor: 'transparent',
											color: 'white',
											border: 'none',
											cursor: 'pointer',
											padding: 0
										}
										}
									>
										{currency.name}
									</button>
								</div>
							))}
							{!(auth.user.isSuperAdmin || hasPermission(["create:Currencies"], auth.user.allowedRoles)) ? "" :
								<div style={{
									margin: '0 10px 10px 0',
									padding: '5px 10px',
									// backgroundColor: '#007bff',
									borderRadius: '4px',
									color: 'white',
									fontWeight: 'bold',
									display: 'inline-block'
								}}>
									<button
										onClick={() => handleAddEditModal(item)}
										style={{
											backgroundColor: 'transparent',
											color: 'white',
											border: 'none',
											cursor: 'pointer',
											padding: 0
										}}
										disabled={!(auth.user.isSuperAdmin || hasPermission(["create:Currencies"], auth.user.allowedRoles))}
									>
										<div className="badge badge-soft-primary font-size-14">
											Edit currency
										</div>
									</button>
								</div>}
						</div>
					);
				}
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: (hasPermission(
					[
						"update:Networks",
						"delete:Networks",
						"read:Networks",
					],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
				viewColumns: (hasPermission(
					[
						"update:Networks",
						"delete:Networks",
						"read:Networks",
					],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={[
									"update:Networks",
									"read:Networks",
								]}
							>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission
								permission={["delete:Networks"]}
							>
								<button
									onClick={(e) => removeItem(data.id)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},

	];

	const toggleCurrencyStatus = async (data) => {
		try {
			const result = await Swal.fire({
				title: 'Are you sure?',
				text: `Do you want to ${data.isActive ? 'deactivate' : 'activate'} this currency?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes'
			});

			if (result.isConfirmed) {
				const newStatus = !data.isActive;
				const response = await axios.patch(`${apiUrl}/currencies/${data.id}`, {
					isActive: newStatus
				});
				if (response.status === 200) {
					const updatedCurrencies = currencies.map(item => {
						if (item.id === data.id) {
							return { ...item, isActive: newStatus };
						}
						return item;
					});
					Swal.fire(
						'Updated!',
						'The currency status has been updated.',
						'success'
					).then((result) => {
						if (result.value) {
							window.location.reload();
						}
					});
				} else {
					throw new Error('Failed to update currency status');
				}
			}
		} catch (error) {
			Swal.fire(
				'Failed!',
				'Failed to update currency status. Error: ' + error.message,
				'error'
			);
		}


	};

	const toggleItem = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle({ ...data, isActive: !data.isActive }));
			}
		});
	};

	const resultFormatter = (result) => {
		return result.map((item) => {
			return {
				...item,
				active: item,
				action: item,
				currencies: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		if (auth.user.isSuperAdmin) return true;
		const isUpdatePermission = hasPermission(
			["update:Networks"],
			auth.user.allowedRoles
		);
		const isAddPermission = hasPermission(
			["create:Networks"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	const handleSubmitCurrency = async (data) => {
		let currency = {
			name: contractDetails.name,
			contractAddress: contractDetails.contractAddress,
			isActive: true,
			networkId: data.id,
			decimal: contractDetails.decimal
		};

		try {
			const response = await axios.post(`${apiUrl}/currencies`, currency);
			if (response.status === 201) {
				Swal.fire({
					title: 'Success!',
					text: 'Currency has been saved successfully!',
					icon: 'success',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();  // Reload the window to reflect changes
					}
				});
			} else {
				Swal.fire({
					title: 'Failed!',
					text: `An error occurred: ${response.statusText}`,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			}
		} catch (error) {
			Swal.fire({
				title: 'Error!',
				text: 'Error during API call: ' + error.message,
				icon: 'error',
				confirmButtonText: 'OK'
			});
		}
	};

	const addNetwork = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			handleformNetwork(data);
		} else {
			handleformNetwork({
				name: "",
				isActive: true,
				factoryContractAddress: "",
				rpc: "",
				chainId: ""
			});
			useFormikForNetwork.resetForm();
		}
		toggleAddEditNetwork(!isOpenAddEditNetwork);
	}

	const handleSubmitNetwork = async (data) => {
		// console.log("network ", data)

		try {
			const response = await axios.post(`${apiUrl}/networks`, { ...data, isActive: true });
			if (response.status === 201) {
				Swal.fire({
					title: 'Success!',
					text: `Network ${data.name} has been created successfully!`,
					icon: 'success',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				});
			} else {
				Swal.fire({
					title: 'Failed!',
					text: `An error occurred: ${response.statusText}`,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			}
		} catch (error) {
			Swal.fire({
				title: 'Error!',
				text: 'Error during API call: ' + error.message,
				icon: 'error',
				confirmButtonText: 'OK'
			});
		}
	}





	const useFormikForNetwork = useFormik({
		initialValues: {
			name: createNetwork?.name ?? '',
			factoryContractAddress: createNetwork?.factoryContractAddress ?? '',
			chainId: createNetwork?.chainId ?? '',
			rpc: createNetwork?.rpc ?? '',
		},
		validationSchema: Yup.object().shape({
			name: Yup.string()
				.required('Network name is required'),
			factoryContractAddress: Yup.string()
				.matches(/^0x[a-fA-F0-9]{40}$/i, 'Invalid Address')
				.required('Contract address is required'),
			chainId: Yup.string()
				.required('Chain ID is required')
				.matches(/^0x[0-9a-fA-F]+$/, 'Chain ID must be a valid hexadecimal'),
			// .matches(/^\d+$/, 'Chain ID must be numeric'),
			rpc: Yup.string()
				.url('Invalid URL')
				.required('RPC URL is required'),
		}),
		onSubmit: (values) => {
			handleSubmitNetwork(values)
		},
	});

	const useFormikForAddCurrency = useFormik({
		initialValues: {
			contractAddress: contractDetails && contractDetails.contractAddress ? contractDetails.contractAddress : '',
		},
		validationSchema: Yup.object().shape({
			contractAddress: Yup.string()
				.matches(/^0x[a-fA-F0-9]{40}$/i, 'Invalid Address')
				.required('Contract address is required'),
		}),
		onSubmit: (values) => {
			// console.log('Form data', values);
		},
	});

	useEffect(() => {
		const fetchContractDetails = async () => {
			try {
				const web3 = new Web3(window.ethereum);
				const contract = new web3.eth.Contract(Erc20, contractDetails.contractAddress);

				let currencyName = await contract.methods.name().call();
				let symbol = await contract.methods.symbol().call();
				let decimal = await contract.methods.decimals().call();

				setContractDetails(prevDetails => ({
					...prevDetails,
					currencyName,
					symbol,
					decimal: Number(decimal),
				}));

				// console.log("name ", name, "symbol ", symbol, "decimal ", decimal);
				setAddressValidated(true);
			} catch (error) {
				setAddressValidated(false);
			}
		}

		if (contractDetails.contractAddress) {
			fetchContractDetails();
		}

	}, [contractDetails.contractAddress])

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Network Info | Rampstarter</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Network Info" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<ReactDataTable
														url={`${apiUrl}/networks/all`}
														columns={columns()}
														resultFormatter={
															resultFormatter
														}
														setRefresh={
															refreshTableData
														}
														disableFilterIcon={
															true
														}
														disableSearchIcon={
															true
														}
														origin={
															<div className="row justify-content-between align-items-center">
																<div className="col-auto h4">
																	Networks & Currencies
																</div>
																<HasAnyPermission
																	permission={["create:Networks"]}
																>
																	<div className="col-auto">
																		<button
																			type="button"
																			className="btn btn-soft-primary waves-effect waves-light"
																			onClick={() => addNetwork(createNetwork)}
																		>
																			<i className="bx bx-plus font-size-16 align-middle"></i>
																			Add New Network
																		</button>
																	</div>
																</HasAnyPermission>
																<Modal
																	isOpen={
																		isOpenAddEditNetwork
																	}
																	toggle={
																		addNetwork
																	}
																	size="xl"
																	centered={true}
																>
																	<ModalHeader
																		toggle={
																			addNetwork
																		}
																		tag="h4"
																	>
																		{details?.id
																			? "Edit Option"
																			: "Add Option"}
																	</ModalHeader>
																	<ModalBody>
																		<fieldset
																			disabled={
																				!couldHaveAddUpdatePermission()
																			}
																		>
																			<Form
																				onSubmit={useFormikForNetwork.handleSubmit}
																			>
																				<Row>
																					<Col
																						xs={
																							12
																						}
																					>
																						<FormGroup>
																							<Label for="name">Network Name</Label>
																							<Input
																								id="name"
																								name="name"
																								type="text"
																								placeholder="Enter Network Name"
																								onChange={useFormikForNetwork.handleChange}
																								onBlur={useFormikForNetwork.handleBlur}
																								value={useFormikForNetwork.values.name}
																								invalid={useFormikForNetwork.touched.name && !!useFormikForNetwork.errors.name}
																							/>
																							{useFormikForNetwork.touched.name && useFormikForNetwork.errors.name && (
																								<FormFeedback>{useFormikForNetwork.errors.name}</FormFeedback>
																							)}
																						</FormGroup>
																						<FormGroup>
																							<Label for="factoryContractAddress">Contract Address</Label>
																							<Input
																								id="factoryContractAddress"
																								name="factoryContractAddress"
																								type="text"
																								placeholder="Enter Contract Address"
																								onChange={useFormikForNetwork.handleChange}
																								onBlur={useFormikForNetwork.handleBlur}
																								value={useFormikForNetwork.values.factoryContractAddress}
																								invalid={useFormikForNetwork.touched.factoryContractAddress && !!useFormikForNetwork.errors.factoryContractAddress}
																							/>
																							{useFormikForNetwork.touched.factoryContractAddress && useFormikForNetwork.errors.factoryContractAddress && (
																								<FormFeedback>{useFormikForNetwork.errors.factoryContractAddress}</FormFeedback>
																							)}
																						</FormGroup>
																						<FormGroup>
																							<Label for="chainId">ChainId</Label>
																							<Input
																								id="chainId"
																								name="chainId"
																								type="text"
																								placeholder="Enter Chain ID"
																								onChange={useFormikForNetwork.handleChange}
																								onBlur={useFormikForNetwork.handleBlur}
																								value={useFormikForNetwork.values.chainId}
																								invalid={useFormikForNetwork.touched.chainId && !!useFormikForNetwork.errors.chainId}
																							/>
																							{useFormikForNetwork.touched.chainId && useFormikForNetwork.errors.chainId && (
																								<FormFeedback>{useFormikForNetwork.errors.chainId}</FormFeedback>
																							)}
																						</FormGroup>
																						<FormGroup>
																							<Label for="rpc">RPC</Label>
																							<Input
																								id="rpc"
																								name="rpc"
																								type="text"
																								placeholder="Enter RPC URL"
																								onChange={useFormikForNetwork.handleChange}
																								onBlur={useFormikForNetwork.handleBlur}
																								value={useFormikForNetwork.values.rpc}
																								invalid={useFormikForNetwork.touched.rpc && !!useFormikForNetwork.errors.rpc}
																							/>
																							{useFormikForNetwork.touched.rpc && useFormikForNetwork.errors.rpc && (
																								<FormFeedback>{useFormikForNetwork.errors.rpc}</FormFeedback>
																							)}
																						</FormGroup>
																					</Col>
																				</Row>


																				<Row>
																					<Col>
																						<div className="text-end mt-3">
																							<button
																								type="submit"
																								className="btn btn-success save-user"
																							>
																								Save
																							</button>
																						</div>
																					</Col>
																				</Row>
																			</Form>
																		</fieldset>
																	</ModalBody>
																</Modal>


															</div>
														}
														rowsPerPage={10}
													/>
													{/* </HasAnyPermission> */}

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="xl"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?.id
																? "Edit Option"
																: "Add Option"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		handleSubmitCurrency(details);
																		return false;
																	}}
																>
																	<Row>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Network
																				</Label>
																				<Input
																					name="networkName"
																					type="text"
																					readOnly
																					onChange={setContractDetails
																					}
																					value={
																						details.name ||
																						""
																					}
																				/>
																			</div>

																			<div className="mb-3">
																				<Label className="form-label">
																					Contract Address
																				</Label>
																				<Input
																					name="contractAddress"
																					type="text"
																					placeholder="ContractAddress"
																					onChange={getContract
																					}
																					value={contractDetails.contractAddress || ""}
																				/>
																			</div>
																		</Col>
																	</Row>

																	<Row>
																		<Col
																			xs={
																				6
																			}
																		>
																			<div>
																				<div className="mb-3">
																					<div className="col-auto">

																					</div>
																				</div>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col
																			xs={
																				6
																			}
																		// key={`data${index}`}
																		>
																			<Card>
																				<CardBody>
																					<span className="m-1 text-primary" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
																						Add New Currency
																					</span>
																					<div className="d-flex flex-wrap align-items-center mb-4">
																						<h5 className="card-title me-2">
																						</h5>
																					</div>

																					<div>
																						<div className="mb-3">
																							<Input
																								placeholder="Currency"
																								name="currencyName"
																								type="text"
																								disabled={true}
																								value={
																									contractDetails.currencyName
																								}
																								onChange={handleChangeData(
																									"currencyName"

																								)}
																								required
																							/>
																						</div>
																						<div className="mb-3">
																							<Input
																								placeholder="Address"
																								name="contractAddress"
																								type="text"
																								disabled={true}
																								value={
																									contractDetails.contractAddress
																								}
																								onChange={handleChangeData(
																									"contractAddress",

																								)}
																								required
																							/>
																						</div>
																						<div className="mb-3">
																							<Input
																								placeholder="Decimal"
																								name="currencyDecimal"
																								type="text"
																								disabled={true}
																								value={
																									contractDetails.decimal
																								}
																								onChange={handleChangeData(
																									"currencyDecimal",

																								)}
																								required
																							/>
																						</div>

																						<div className="text-end mt-3">
																							<button
																								type="submit"
																								className="btn btn-success save-user"
																							>
																								Save
																							</button>
																						</div>

																					</div>
																				</CardBody>

																			</Card>

																		</Col>
																		<Row>
																			{details.currencies &&
																				details.currencies.map((detail, index) => (
																					<Col xs={6} key={`currency-${index}`}>
																						<Card>
																							<CardBody>
																								<div className="d-flex flex-wrap align-items-center mb-4">
																									<h5 className="card-title me-2">
																										<button
																											type="button"
																											className="btn btn-soft-primary waves-effect waves-light"
																											onClick={(e) => removeCurrency(e, index)}
																										>
																											<i className="bx bx-trash font-size-16 align-middle"></i>
																										</button>
																									</h5>
																									<div className="ms-auto">
																										<div className="mb-3">
																											<select
																												className="form-control"
																												name="active"
																												value={detail.isActive ? "true" : "false"}
																												disabled={
																													!(hasPermission(
																														["update:Currences"],
																														auth.user.allowedRoles
																													) || auth.user.isSuperAdmin)
																												}
																												onChange={(e) => currencyToggleItem(e, index)}
																											>
																												<option value="true">Active</option>
																												<option value="false">Inactive</option>
																											</select>
																										</div>
																									</div>
																								</div>
																								<div>
																									<div className="mb-3">
																										<Input
																											placeholder="Currency"
																											name="name"
																											type="text"
																											value={detail.name || ""}
																											onChange={(e) => handleChangeData("name", index, e.target.value)}
																											required
																											disabled
																										/>
																									</div>
																									<div className="mb-3">
																										<Input
																											placeholder="Address"
																											name="address"
																											type="text"
																											value={detail.contractAddress || ""}
																											onChange={(e) => handleChangeData("address", index, e.target.value)}
																											required
																											disabled
																										/>
																									</div>
																									<div className="mb-3">
																										<Input
																											placeholder="Decimal"
																											name="decimal"
																											type="text"
																											value={detail.decimal || ""}
																											onChange={(e) => handleChangeData("decimal", index, e.target.value)}
																											disabled
																										/>
																									</div>
																								</div>
																							</CardBody>
																						</Card>
																					</Col>
																				))}
																		</Row>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default LaunchpadOption;