import React from "react";
import { Col, Row, Card, CardBody, CardHeader, Input,FormText, FormFeedback   } from "reactstrap";
import Dropzone from "react-dropzone";

const TeamTokenomicWhitepaperInfoStepFour = ({
	validationForWhitePaper,
	handleAddNewTeamInfo,
	teamInfo,
	handleDeleteTeamInfo,
	handleEditTeamInfo,
	handleAddNewTokenomic,
	tokenomicDetails,
	handleDeleteTokenomic,
	handleEditTokenomic,
	selectedFile,
	whitepaper,
}) => {
	return (
		<React.Fragment>
			<div>
				<div>
					<div className="text-center mb-4">
						<h5>Team & Tokenomics & Whitepaper Info</h5>
						<p className="card-title-desc">Team information</p>
					</div>
				</div>
				<Card>
					<CardHeader>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">{"Team Info"}<span style={{ color: 'red' }}>*</span></h5>
							<button
								type="button"
								className="btn btn-soft-primary waves-effect waves-light"
								onClick={(e) => {
									e.preventDefault();
									handleAddNewTeamInfo();
								}}
							>
								<i className="bx bx-plus font-size-16 align-middle"></i>
							</button>
						</div>
					</CardHeader>
					<CardBody>
						<div>
							<Row>
								<Col xs={12}>
									{/* {console.log("-------------teamInfo---------- ",teamInfo[0],teamInfo[0].name.length, teamInfo.some(member => member.active))} */}
									{teamInfo[0]?.name?.length > 0  ? (
										<div className="row">
											{teamInfo.map(
												(team, teamInfoId) => (
													<div
													className="teamInfoStage col-lg-4 col-md-4"
													key={teamInfoId}
												
														>
														<Card className="m-1">
															<div className="card-header align-items-center d-flex">
																<h4 className="card-title mb-0 flex-grow-1">
																	Team member{" "}
																	{teamInfoId +
																		1}
																</h4>
															</div>
															<CardBody>
																<div className="mt-0">
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<span className="font-size-12">
																				Name
																			</span>
																		</div>

																		<div className="flex-shrink-0">
																			<span className="font-size-12 fw-medium">
																				{
																					team.name
																				}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="mt-0">
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<span className="font-size-12">
																				Designation<span style={{ color: 'red' }}></span>
																			</span>
																		</div>

																		<div className="flex-shrink-0">
																			<span className="font-size-12 fw-medium">
																				{
																					team.designation
																				}
																			</span>
																		</div>
																	</div>
																</div>

																{/* <div className="mt-0">
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<span className="font-size-12">
																				Linkedin
																			</span>
																		</div>

																		<div className="flex-shrink-0">
																			<span className="breakWord font-size-12 fw-medium">
																				{
																					team.linkedin
																				}
																			</span>
																		</div>
																	</div>
																</div> */}


															</CardBody>
															<div className="actionBtn">
																<div className="d-flex align-items-center">
																	<div className="flex-grow-1">
																		<span className="font-size-16">
																			<button
																				type="button"
																				className="btn btn-soft-danger waves-effect waves-light"
																				onClick={(
																					e
																				) => {
																					e.preventDefault();
																					handleDeleteTeamInfo(
																						teamInfoId
																					);
																				}}
																			>
																				<i className="mdi mdi-trash-can d-block font-size-16 align-middle"></i>
																			</button>
																		</span>
																	</div>

																	<div className="flex-shrink-0">
																		<button
																			type="button"
																			className="btn btn-soft-success waves-effect waves-light"
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				handleEditTeamInfo(
																					teamInfoId
																				);
																			}}
																		>
																			<i className="mdi mdi-pencil d-block font-size-16 align-middle"></i>
																		</button>
																	</div>
																</div>
															</div>
														</Card>
													</div>
												)
											)}
										</div>
									) : (
										<div className="text-center ">
											<span className="text-center text-muted">{`No Team`}</span>
										</div>
									)}
								</Col>
							</Row>
						</div>
					</CardBody>
				</Card>

				<Card>
					<CardHeader>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">{"Tokenomics"}<span style={{ color: 'red' }}>*</span></h5>
							<button
								type="button"
								className="btn btn-soft-primary waves-effect waves-light"
								onClick={(e) => {
									e.preventDefault();
									handleAddNewTokenomic();
								}}
							>
								<i className="bx bx-plus font-size-16 align-middle"></i>
							</button>
						</div>
					</CardHeader>
					<CardBody>
						<div>
							<Row>
								<Col xs={12}>
									{/* {console.log("tokenomicDetails ------- tokenomicDetails ",tokenomicDetails)} */}
									{/* {tokenomicDetails[0]?.active  ? ( */}
									{ tokenomicDetails[0]?.label?.length > 0 ?(
										<div className="row">
											{tokenomicDetails.map(
												(
													tokenomicDetail,
													tokenomicDetailId
												) => (
													<div
														className="col-lg-4 teamInfoStage col-md-4 "
														key={tokenomicDetailId}
														// style={{
														// 	width: "100%",
														// 	height: "100%",
														// }}
													>
														<Card className="m-1">
															<div className="card-header align-items-center d-flex">
																<h4 className="card-title mb-0 flex-grow-1">
																	{" "}
																	{tokenomicDetailId +
																		1}
																</h4>
																{/* <div className="flex-shrink-0">
													<span className="font-size-12 fw-medium">
														{
															team.name
														}
													</span>
												</div> */}
															</div>
															<CardBody>
																<div className="mt-0">
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<span className="font-size-12">
																				Name<span style={{ color: 'red' }}>*</span>
																			</span>
																		</div>

																		<div className="flex-shrink-0">
																			<span className="font-size-12 fw-medium">
																				{
																					tokenomicDetail.label
																				}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="mt-0">
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<span className="font-size-12">
																				Value
																				(%)<span style={{ color: 'red' }}>*</span>
																			</span>
																		</div>

																		<div className="flex-shrink-0">
																			<span className="font-size-12 fw-medium">
																				{
																					tokenomicDetail.percentage
																				}
																			</span>
																		</div>
																	</div>
																</div>
															</CardBody>
															<div className="actionBtn">
																<div className="d-flex align-items-center">
																	<div className="flex-grow-1">
																		<span className="font-size-16">
																			<button
																				type="button"
																				className="btn btn-soft-danger waves-effect waves-light"
																				onClick={(
																					e
																				) => {
																					e.preventDefault();
																					handleDeleteTokenomic(
																						tokenomicDetailId
																					);
																				}}
																			>
																				<i className="mdi mdi-trash-can d-block font-size-16 align-middle"></i>
																			</button>
																		</span>
																	</div>

																	<div className="flex-shrink-0">
																		<button
																			type="button"
																			className="btn btn-soft-success waves-effect waves-light"
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				handleEditTokenomic(
																					tokenomicDetailId
																				);
																			}}
																		>
																			<i className="mdi mdi-pencil d-block font-size-16 align-middle"></i>
																		</button>
																	</div>
																</div>
															</div>
														</Card>
													</div>
												)
											)}
										</div>
									) : (
										<div className="text-center ">
											<span className="text-center text-muted">{`No Tokenomics`}</span>
										</div>
									)}
								</Col>
							</Row>
						</div>
					</CardBody>
				</Card>

				<Card>
					<CardHeader>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">{"White paper"}<span style={{ color: 'red' }}>*</span></h5>
						</div>
					</CardHeader>
					<CardBody>
						<div>
							<Row>
								<Col xs={12}>
								<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									White Paper<span style={{ color: 'red' }}>*</span>
								</label>

								<Input
									name="whitepaperUrl"
									type="text"
									onChange={
										
										validationForWhitePaper.handleChange

									}
									onBlur={validationForWhitePaper.handleBlur}
									value={
										validationForWhitePaper.values
											.whitepaperUrl || ""
									}
									invalid={
										validationForWhitePaper.touched
											.whitepaperUrl &&
										validationForWhitePaper.errors
											.whitepaperUrl
											? true
											: false
									}
								/>
								{validationForWhitePaper.touched
									.whitepaperUrl &&
								validationForWhitePaper.errors
									.whitepaperUrl ? (
									<FormFeedback type="invalid">
										{
											validationForWhitePaper.errors
												.whitepaperUrl
										}
									</FormFeedback>
								) : null}
								
							</div>
								</Col>
							</Row>
						</div>
					</CardBody>
				</Card>
			</div>
		</React.Fragment>
	);
};

export default TeamTokenomicWhitepaperInfoStepFour;
