import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import {
	create,
	update,
	remove,
	clearResponse,
} from "../../store/admin/actions";

const Admin = (props) => {
	const dispatch = useDispatch();
	const getLoaderStatus = useRef(null);
	const refreshTableData = useRef(null);
	const [roles, setRoles] = useState([]);
	const { response, auth } = useSelector((state) => ({
		response: state.admin.response,
		auth: state.auth,
	  }));
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isOpenUpdatePasswordModal, toggleUpdatePasswordModal] = useState(false);
	const [details, handleFormData] = useState({
		active: true,
	});
	const [passwordDetails, handlePasswordFormData] = useState({
		active: true,
	});
	const history = useHistory();

	useEffect(() => {
		if (response) {
			if (response.code === "200" || response.message === "User created." ) {
				refreshTableData.current();
				if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
				Swal.fire({
					title: "Success!",
					text: response.message,
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			} 
			else if (response.message) {
				refreshTableData.current();
				Swal.fire({
					title: "Error!",
					text: response.message,
					icon: "error",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
		if (response.affected === 1) {
			refreshTableData.current();
				if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			if (response.generatedMaps){
				Swal.fire({
					title: "Success!",
					text: "User Updated",
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			}else{
				Swal.fire({
					title: "Success!",
					text: "User deleted",
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
	}, [dispatch, response]);
	
	useEffect(() => {
		const fetchRoles = async () => {
		  try {
			const response = await axios.get(`${apiUrl}/auth/roles`);
			await setRoles(response.data); 
		  } catch (error) {
		  }
		};
		fetchRoles(); 
	  }, [dispatch]); 
  
	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(id));
				}
			});
		}
	};

	const updatePassword = (id) => {
		if (!isEmpty(id)) {
			handlePasswordFormData({ id:id});
		} 
		toggleUpdatePasswordModal(!isOpenUpdatePasswordModal);
	};

	let validationSchema = {
		username: Yup.string().required("Please enter name."),
		email: Yup.string().email().required("Please enter valid email."),
		role: Yup.string().required("Please select role."),
            // .when(`${details.id}`, {
            //     is: undefined,
            //     then: Yup.string().required("Please select role."),
            //     otherwise: Yup.string().required("Please select role.")
            // }),
		password: Yup.string().required("Please enter password.")
			.matches(
				/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
				"Password must contain at least eight character long one least one uppercase and loewrcase letter, number and special character."
			),
		confirmPassword: Yup.string()
			.required("Please enter confirm password.")
			.oneOf([Yup.ref("password"), null], "Passwords must match."),
	};

	let validationSchemaPassword = {
		password: Yup.string().required("Please enter password.")
			.matches(
				/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
				"Password must contain at least eight character long one least one uppercase and loewrcase letter, number and special character."
			),
		confirmPassword: Yup.string()
			.required("Please enter confirm password.")
			.oneOf([Yup.ref("password"), null], "Passwords must match."),
	};

	if (details.id) {
		delete validationSchema.password;
		delete validationSchema.confirmPassword;
	}
	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			id : details && details.id ? details.id : "",
			username: details && details.username ? details.username : "",
			email: details && details.email ? details.email : "",
			role: details && details.role ? details.role : "",
			password : details && details.password ? details.password : "",
			confirmPassword : details && details.confirmPassword ? details.confirmPassword : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: async (values) => {
			if (details && details.id) { 
				const intValue = parseInt(values.role, 10);
				if (isNaN(intValue)) delete values.role
				delete values.password
				delete values.confirmPassword
				let resp = await dispatch(
					update({
						...values,
						id: details.id,
					})
				);
			} else {
				console.log("Hi")
				delete values.confirmPassword;
				delete values.id;
				await dispatch(create({ ...values }));
			}
		}
	};
	
	const validation = useFormik(useFormikOptions);

	const useFormikOptionsPassword = {
		enableReinitialize: true,
		initialValues: {
			password : passwordDetails && passwordDetails.password ? passwordDetails.password : "",
			confirmPassword : passwordDetails && passwordDetails.confirmPassword ? passwordDetails.confirmPassword : "",
		},
		validationSchema: Yup.object(validationSchemaPassword),
		onSubmit: async (values) => {
			if (passwordDetails && passwordDetails.id) { 
				Swal.fire({
					title: 'Are you sure?',
					text: "Do you want to change the password?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, change it!'
				}).then(async (result) => {
					if (result.isConfirmed) {
						delete values.confirmPassword;
						let resp = await dispatch(
							update({
								...values,
								id: passwordDetails.id,
							}))
							
						}
					}
				).then( () => {
					history.push('/logout');	
				}
				)
			} 
		}
	};
	const passwordValidation = useFormik(useFormikOptionsPassword);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			handleFormData({ ...data, role: data.role.name});
		} else {
			handleFormData({
				active: true,
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "ID",
			name: "id",
			options: {
				filter: false,
				sortOrder: 'asc',
				sortCompare: (sortOrder) => {
					return (obj1, obj2) => {
					  return (obj1.data - obj2.data) * (sortOrder === 'asc' ? 1 : -1);
					};
				  },
				download: true,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className="font-size-14">
								{value}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Name",
			name: "username",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className="badge badge-soft-success font-size-14">
								{value}
							</div>
						</div>
					);
				},
			},
		},
		{
			name: "email",
			label: "Email",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className="font-size-14">
								{value}
							</div>
						</div>
					);
				},
			},
		},
		{
			name: "role",
			label: "Role",
			options: {
				sort: false,
				download: false,
				filter: false,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className={`badge font-size-14 badge-soft-success`}>
								{value?.name ? value.name : 'Super Admin'}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: true,
				customBodyRender: (data) => {
					const isSuperAdmin = data.isSuperAdmin;
					return (
							<HasAnyPermission
							permission={["create:Users", "update:Users"]}
							>
							<div className="d-flex flex-wrap gap-2 text-center">
							{!isSuperAdmin ? (
								<>
								<HasAnyPermission
									permission={[ "update:Users"]}
									>
									<button
										type="button"
										className="btn btn-sm btn-primary"
										onClick={() => handleAddEditModal(data)}
									>
										Edit
									</button>
									</HasAnyPermission>
									<HasAnyPermission
									permission={[ "delete:Users"]}
									>
									<button
										type="button"
										className="btn btn-sm btn-danger"
										onClick={() => removeItem(data.id)}
									>
										Delete
									</button>
									</HasAnyPermission>
								</>
							) : 
							<button
										display = {auth.user.isSuperAdmin}
										type="button"
										className="btn btn-soft-danger"
										onClick={() => updatePassword(data.id)}
									>
										Change Password
									</button>
							}
						</div>
						</HasAnyPermission>
					);
				},
			},
		},
	];
	
	// Ensure your table data is structured correctly
	const resultFormatter = (result) => {
		return result.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	const handlePageChange = async (newPage) => {
		await setPage(newPage+1); 
		await refreshTableData.current();
	};
  
	const handleRowsPerPageChange = async (newRowsPerPage) => {
		await setPage(1)
	  	await setRowsPerPage(newRowsPerPage);
	  	await refreshTableData.current();
	};

	const couldHaveAddUpdatePermission = () => {
		if (auth.user.isSuperAdmin) return true;
		const isUpdatePermission = hasPermission(
			["update:Users"],
			auth.user.allowedRoles 
		);
		const isAddPermission = hasPermission(
			["create:Users"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details.id)) return true;
		else if (isAddPermission && isEmpty(details.id)) return true;
		else return false;
	};

		return (
			<React.Fragment>
				<div className="page-content">
					<Helmet>
						<title>Admin | Rampstarter</title>
					</Helmet>
					<Container fluid>
						{/* Render Breadcrumbs */}
						<Breadcrumbs title="Users" breadcrumbItem="List" />
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<Row>
											<Col xl="12">
												<div className="table-rep-plugin">
													<div className="table-responsive">
														<HasAnyPermission
															permission={[
																"read:Users",
															]}
														>
															<ReactDataTable
																url={`${apiUrl}/user/all`}
																columns={columns()} 
																resultFormatter={resultFormatter}
																setRefresh={refreshTableData}
																getLoaderStatus={getLoaderStatus}
																disableFilterIcon={true}
																disableSearchIcon={false}
																onChangePage={handlePageChange}
																onChangeRowsPerPage={handleRowsPerPageChange}
																sort={'asc'}
																sortColumn={'id'}
																origin={
																	<div className="row">
																		<div className="col-auto h4">
																			Users&nbsp;&nbsp;
																			<HasAnyPermission
																				permission={["create:Users"]}
																			>
																				<button
																					onClick={() => {
																						handleAddEditModal();
																					}}
																					type="button"
																					className="btn btn-primary waves-effect waves-light"
																				>
																					Add User
																				</button>
																			</HasAnyPermission>
																		</div>
																	</div>
																}
																rowsPerPage={10}
	/>
														</HasAnyPermission>
	
														<Modal
															isOpen={
																isOpenAddEditModal
															}
															toggle={
																handleAddEditModal
															}
															backdrop={"static"}
															size="lg"
															centered={true}
														>
															<ModalHeader
																toggle={
																	handleAddEditModal
																}
																tag="h4"
															>
																{details?.id
																	? "Edit"
																	: "Add"}
															</ModalHeader>
															<ModalBody>
																<Form
																	disabled
																	onSubmit={(e) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<fieldset
																		disabled={ 
																			!couldHaveAddUpdatePermission()
																		} 
																	>
																		<Row
																			form={
																				"true"
																			}
																		>
																			<Col
																				xs={
																					12
																				}
																			>
																				<div className="mb-3">
																					<Label className="form-label">
																						Name<span style={{ color: 'red' }}>*</span>
																					</Label>
																					<Input
																						name="username"
																						type="text"
																						onChange={
																							validation.handleChange
																						}
																						onBlur={
																							validation.handleBlur
																						}
																						value={
																							validation
																								.values
																								.username ||
																							""
																						}
																						invalid={
																							validation
																								.touched
																								.username &&
																							validation
																								.errors
																								.username
																								? true
																								: false
																						}
																					/>
																					{validation
																						.touched
																						.username &&
																					validation
																						.errors
																						.username ? (
																						<FormFeedback type="invalid">
																							{
																								validation
																									.errors
																									.username
																							}
																						</FormFeedback>
																					) : null}
																				</div>
																			</Col>
	
																			<Col
																				xs={
																					12
																				}
																			>
																				<div className="mb-3">
																					<Label className="form-label">
																						Email<span style={{ color: 'red' }}>*</span>
																					</Label>
																					<Input
																						name="email"
																						type="email"
																						onChange={
																							validation.handleChange
																						}
																						onBlur={
																							validation.handleBlur
																						}
																						value={
																							validation
																								.values
																								.email ||
																							""
																						}
																						invalid={
																							validation
																								.touched
																								.email &&
																							validation
																								.errors
																								.email
																								? true
																								: false
																						}
																					/>
																					{validation
																						.touched
																						.email &&
																					validation
																						.errors
																						.email ? (
																						<FormFeedback type="invalid">
																							{
																								validation
																									.errors
																									.email
																							}
																						</FormFeedback>
																					) : null}
																				</div>
																			</Col>
	
																			{details.id ===
																			undefined ? (
																				<>
																				<div className="mb-3">
																					<Label className="form-label">
																						Role<span style={{ color: 'red' }}>*</span>
																					</Label>
																					{/* {console.log("validation.values.role",validation.values.role)} */}
																					<Input
																						name="role"
																						type="select"
																						onChange={validation.handleChange}
																						onBlur={validation.handleBlur}
																						value={validation.values.role || ""}
																						invalid={validation.touched.role && validation.errors.role ? true : false}
																					>
																						<option disabled value="">
																							Role
																						</option>
																						{roles.map((item) => (
																						<option key={item.id} value={item.id}>
																							{item.roleName}
																						</option>
																						))}
																					</Input>
																				</div>	
																				</>
																			) : <div className="mb-3">
																			<Label className="form-label">
																				Role<span style={{ color: 'red' }}>*</span>
																			</Label>
																			{/* {console.log("validation.values.role",validation.values.role)} */}
																			<Input
																				name="role"
																				type="select"
																				onChange={validation.handleChange}
																				onBlur={validation.handleBlur}
																				value={validation.values.role || ""}
																				invalid={validation.touched.role && validation.errors.role ? true : false}
																			>
																				<option disabled >
																					{details && details.role ? details.role : "Role"}
																				</option>
																				{roles.map((item) => (
																				<option key={item.id} value={item.id}>
																					{item.roleName}
																				</option>
																				))}
																			</Input>
																		</div>}

																			
																			{details.id ===
																			undefined ? (
																				<>
																					<Col
																						xs={
																							12
																						}
																					>
																						<div className="mb-3">
																							<Label className="form-label">
																								Password<span style={{ color: 'red' }}>*</span>
																							</Label>
																							<Input
																								name="password"
																								type="password"
																								onChange={
																									validation.handleChange
																								}
																								onBlur={
																									validation.handleBlur
																								}
																								value={
																									validation
																										.values
																										.password ||
																									""
																								}
																								invalid={
																									validation
																										.touched
																										.password &&
																									validation
																										.errors
																										.password
																										? true
																										: false
																								}
																							/>
																							{validation
																								.touched
																								.password &&
																							validation
																								.errors
																								.password ? (
																								<FormFeedback type="invalid">
																									{
																										validation
																											.errors
																											.password
																									}
																								</FormFeedback>
																							) : null}
																						</div>
																					</Col>
	
																					<Col
																						xs={
																							12
																						}
																					>
																						<div className="mb-3">
																							<Label className="form-label">
																								Confirm
																								Password<span style={{ color: 'red' }}>*</span>
																							</Label>
																							<Input
																								name="confirmPassword"
																								type="password"
																								onChange={
																									validation.handleChange
																								}
																								onBlur={
																									validation.handleBlur
																								}
																								value={
																									validation
																										.values
																										.confirmPassword ||
																									""
																								}
																								invalid={
																									validation
																										.touched
																										.confirmPassword &&
																									validation
																										.errors
																										.confirmPassword
																										? true
																										: false
																								}
																							/>
																							{validation
																								.touched
																								.confirmPassword &&
																							validation
																								.errors
																								.confirmPassword ? (
																								<FormFeedback type="invalid">
																									{
																										validation
																											.errors
																											.confirmPassword
																									}
																								</FormFeedback>
																							) : null}
																						</div>
																					</Col>
																				</>
																			) : null}
																		</Row>
																		<Row>
																			<Col>
																				<div className="text-end mt-3">
																					<button
																						type="submit"
																						className="btn btn-success save-user"
																					>
																						Submit
																					</button>
																				</div>
																			</Col>
																		</Row>
																	</fieldset>
																</Form>
															</ModalBody>
														</Modal>

														<Modal
															isOpen={
																isOpenUpdatePasswordModal
															}
															toggle={
																updatePassword
															}
															backdrop={"static"}
															size="lg"
															centered={true}
														>
															<ModalHeader
																toggle={
																	updatePassword
																}
																tag="h4"
															>
																Update Password
															</ModalHeader>
															<ModalBody>
																<Form
																	disabled
																	onSubmit={(e) => {
																		e.preventDefault();
																		passwordValidation.handleSubmit();
																		return false;
																	}}
																>
																	<fieldset
																		disabled={ 
																			!auth.user.isSuperAdmin
																		} 
																	>
																		<Row
																			form={
																				"true"
																			}
																		>
																		<>
																			<Col
																				xs={
																					12
																				}
																			>
																				<div className="mb-3">
																					<Label className="form-label">
																						New Password<span style={{ color: 'red' }}>*</span>
																					</Label>
																					<Input
																						name="password"
																						type="password"
																						onChange={
																							passwordValidation.handleChange
																						}
																						onBlur={
																							passwordValidation.handleBlur
																						}
																						value={
																							passwordValidation
																								.values
																								.password ||
																							""
																						}
																						invalid={
																							passwordValidation
																								.touched
																								.password &&
																							passwordValidation
																								.errors
																								.password
																								? true
																								: false
																						}
																					/>
																					{passwordValidation
																						.touched
																						.password &&
																					passwordValidation
																						.errors
																						.password ? (
																						<FormFeedback type="invalid">
																							{
																								passwordValidation
																									.errors
																									.password
																							}
																						</FormFeedback>
																					) : null}
																				</div>
																			</Col>
	
																					<Col
																						xs={
																							12
																						}
																					>
																						<div className="mb-3">
																							<Label className="form-label">
																								Confirm New
																								Password<span style={{ color: 'red' }}>*</span>
																							</Label>
																							<Input
																								name="confirmPassword"
																								type="password"
																								onChange={
																									passwordValidation.handleChange
																								}
																								onBlur={
																									passwordValidation.handleBlur
																								}
																								value={
																									passwordValidation
																										.values
																										.confirmPassword ||
																									""
																								}
																								invalid={
																									passwordValidation
																										.touched
																										.confirmPassword &&
																									passwordValidation
																										.errors
																										.confirmPassword
																										? true
																										: false
																								}
																							/>
																							{passwordValidation
																								.touched
																								.confirmPassword &&
																							passwordValidation
																								.errors
																								.confirmPassword ? (
																								<FormFeedback type="invalid">
																									{
																										passwordValidation
																											.errors
																											.confirmPassword
																									}
																								</FormFeedback>
																							) : null}
																						</div>
																					</Col>
																				</>
																		</Row>
																		<Row>
																			<Col>
																				<div className="text-end mt-3">
																					<button
																						type="submit"
																						className="btn btn-success save-user"
																					>
																						Submit
																					</button>
																				</div>
																			</Col>
																		</Row>
																	</fieldset>
																</Form>
															</ModalBody>
														</Modal>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
};

export default Admin;
